<template>
	<div class="lp" id="js_pagetop">
		<h1 class="lp__header"><img src="~@/assets/img/lp_heatstroke2022/title.png" alt="暑さに負けない！ 熱中症＆夏バテ 対策特集 presented by 頭痛ーる"></h1>

		<!-- イントロ -->
		<div class="lp__intro">
			<p class="lp__intro--text">夏に心配な体の不調と言えば<em>熱中症</em>。また、<em>夏バテ</em>や<em>冷房病</em>といった不調も起こりやすい時期です。<br>今回は、気圧予報アプリ<em>頭痛ーる</em>と一緒に夏の不調を予防し、夏を元気に乗り切るためのコツをご紹介します。</p>
		</div>
		<!-- /イントロ -->

		<!-- 目次 -->
		<nav class="lp__link">
			<h2 class="lp__link--title"><img src="~@/assets/img/lp_heatstroke2022/summer_title.png" alt="with 頭痛ーるで夏を元気に！"></h2>
			<ul class="lp__link--list">
				<li><a href="javascript:void(0)" @click="onScroll('js_pressure')" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク1" data-gtm-event-act-label="trackingLpHeatstroke2022Btn01"><img src="~@/assets/img/lp_heatstroke2022/linklist_01.png" alt="夏の気圧変動の傾向は？"></a></li>
				<li><a href="javascript:void(0)" @click="onScroll('js_summerheat')" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク2" data-gtm-event-act-label="trackingLpHeatstroke2022Btn02"><img src="~@/assets/img/lp_heatstroke2022/linklist_02.png" alt=" 絵でわかる夏の不調対策"></a></li>
				<li><a href="javascript:void(0)" @click="onScroll('js_check')" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク3" data-gtm-event-act-label="trackingLpHeatstroke2022Btn03"><img src="~@/assets/img/lp_heatstroke2022/linklist_03.png" alt="1分で診断！寒暖差疲労チェック"></a></li>
			</ul>
		</nav>
		<!-- /目次 -->

		<!-- 健康天気指数 -->
		<section class="lp-contents">
			<div class="lp-contents__inner lp-contents__inner--healthweather">
				<h2 class="lp-contents__title"><img src="~@/assets/img/lp_heatstroke2022/healthweather_title.png" alt="便利な新機能登場" class="lp-contents__title--healthweather"><span>熱中症の危険度もわかる</span>健康天気指数を毎日見よう!</h2>
				<div class="lp-contents__healthweather">
					<h3 class="lp-contents__healthweather--subtitle">頭痛ーるの新機能「健康天気指数」とは？</h3>
					<ul class="lp-contents__healthweather--list">
						<li>・花粉、インフルエンザといった<em>季節ごとの健康に関わる指数</em>を毎日お知らせします。</li>
						<li>・夏の時期には、<em>熱中症</em>や<em>紫外線</em>の指数もお知らせ！</li>
						<li>・健康管理士によるワンポイントアドバイスも。</li>
					</ul>
					<div class="lp-contents__healthweather--text01">
						<i class="sprite-balloon img02" />
						<p>指数は気温・気圧・湿度などによって日々変化するので、毎日チェックしてほしいのじゃ！</p>
					</div>
					<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId }, hash: '#js_healthweather' })" class="lp-contents__button gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク4" data-gtm-event-act-label="trackingLpHeatstroke2022Btn04">今日の健康天気指数を見てみる</a>
					<div v-if="isAndroid" class="lp-contents__healthweather--text02">
						<i class="sprite-balloon img03" />
						<p>熱中症に警戒が必要な日はプッシュ通知でもお知らせするよ～。一緒に熱中症を予防しようニャ！</p>
					</div>
				</div>
			</div>
		</section>
		<!-- /健康天気指数 -->

		<!-- 気圧予報 -->
		<section class="lp-contents" id="js_pressure">
			<div class="lp-contents__inner">
				<h2 class="lp-contents__title"><span>夏も油断しないで</span>気圧グラフを毎日見よう！</h2>
				<div class="lp-contents__pressure">
					<div class="lp-contents__pressure--text01">
						<i class="sprite-balloon img04" />
						<p>梅雨が明けると低気圧の心配もなくなるのかニャ？</p>
					</div>
					<div class="lp-contents__pressure--text02">
						<i class="sprite-balloon img05" />
						<p>いやそうとも言えないのじゃ！<br>実は、過去数年分の傾向を見ると7月・8月も、気象病の起こりやすい気圧の日が少なくなかったのじゃよ。</p>
					</div>
					<div class="lp-contents__pressure--forecast">
						<h3>2020年7月の東京の気圧の状況</h3>
						<div class="lp-contents__pressure--forecast-box">
							<p>赤マークが気圧の変動が大きく特に注意が必要で、次いで黄色、黄緑、水色で、水色は体調への影響が小さかった日を示しています。また、紫色は気圧の上昇で注意が必要だった日です。</p>
							<img src="~@/assets/img/lp_heatstroke2022/pressure_img.png" alt="気圧カレンダーイメージ">
						</div>
					</div>
					<div class="lp-contents__pressure--text03">
						<i class="sprite-balloon img06" />
						<p>にゃんと!?<br>じゃあ、夏の間も油断せず、頭痛ーるの気圧グラフをチェックしないとニャ！</p>
					</div>
					<div class="lp-contents__pressure--catch">▼アプリで気圧グラフを見てみよう▼</div>
					<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="lp-contents__button gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク5Android" data-gtm-event-act-label="trackingLpHeatstroke2022Btn05_android">今すぐダウンロード</a>
					<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="lp-contents__button gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク5iOS" data-gtm-event-act-label="trackingLpHeatstroke2022Btn05_ios">今すぐダウンロード</a>
				</div>
			</div>
		</section>
		<!-- /気圧予報 -->

		<!-- 夏の不調対策 -->
		<section class="lp-contents" id="js_summerheat">
			<div class="lp-contents__inner">
				<h2 class="lp-contents__title"><span>イラストでやさしく解説！</span>夏の不調対策</h2>
				<div class="lp-contents__summerheat">
					<h3 class="lp-contents__summerheat--title">あなたが気になる夏の不調は？<br><span>▼イラストをタップしよう▼</span></h3>
					<ul class="lp-contents__summerheat--list">
						<li class="lp-contents__summerheat--link01"><a href="javascript:void(0)" @click="clickModalLink(1, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク6" data-gtm-event-act-label="trackingLpHeatstroke2022Btn06"><img src="~@/assets/img/lp_heatstroke2022/summerheat_info01.png" alt="7月に現れやすい気象病と体調管理 熱中症"></a></li>
						<li class="lp-contents__summerheat--link02"><a href="javascript:void(0)" @click="clickModalLink(2, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク7" data-gtm-event-act-label="trackingLpHeatstroke2022Btn07"><img src="~@/assets/img/lp_heatstroke2022/summerheat_info02.png" alt="自律神経の乱れがカギ!? 夏バテ 主な原因と対処法3選"></a></li>
						<li class="lp-contents__summerheat--link03"><a href="javascript:void(0)" @click="clickModalLink(3, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク8" data-gtm-event-act-label="trackingLpHeatstroke2022Btn08"><img src="~@/assets/img/lp_heatstroke2022/summerheat_info03.png" alt="冷房病（クーラー病） 症状と予防方法"></a></li>
					</ul>
					<h3 class="lp-contents__summerheat--title">ちょっとしたコツで夏を元気に！<br><span>▼イラストをタップしよう▼</span></h3>
					<ul class="lp-contents__summerheat--list">
						<li class="lp-contents__summerheat--link04"><a href="javascript:void(0)" @click="clickModalLink(4, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク9" data-gtm-event-act-label="trackingLpHeatstroke2022Btn9"><img src="~@/assets/img/lp_heatstroke2022/summerheat_step01.png" alt="夏の不調対策 夏バテ予防にはまず睡眠"></a></li>
						<li class="lp-contents__summerheat--link05"><a href="javascript:void(0)" @click="clickModalLink(5, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク10" data-gtm-event-act-label="trackingLpHeatstroke2022Btn10"><img src="~@/assets/img/lp_heatstroke2022/summerheat_step02.png" alt="夏の不調対策 夏こそしっかり湯船につかろう"></a></li>
						<li class="lp-contents__summerheat--link06"><a href="javascript:void(0)" @click="clickModalLink(6, $event)" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク11" data-gtm-event-act-label="trackingLpHeatstroke2022Btn11"><img src="~@/assets/img/lp_heatstroke2022/summerheat_step03.png" alt="夏の不調対策 水だけじゃダメ!?水分補給のコツ"></a></li>
					</ul>
				</div>
			</div>
		</section>
		<!-- /夏の不調対策 -->

		<!-- チェック -->
		<section class="lp-contents" id="js_check">
			<div class="lp-contents__inner">
				<h2 class="lp-contents__title"><span>冷房を使う季節は要注意！</span>寒暖差疲労を知ろう</h2>
				<div class="lp-contents__check">
					<p class="lp-contents__check--text">夏の時期は、冷房による冷えや室内外の寒暖差によって不調が起こる場合があります。<br>あなたは寒暖差疲労になりやすいのか、1分でできるセルフチェックで確認してみましょう。</p>
					<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckTemperatureDifference` } })" class="lp-contents__check--link gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク12" data-gtm-event-act-label="trackingLpHeatstroke2022Btn12"><img src="~@/assets/img/lp_heatstroke2022/selfchecktemperature_bnr.png" alt="医師監修 寒暖差疲労セルフチェック"></a>
					<div class="lp-contents__check--profile">
						<img src="~@/assets/img/lp_heatstroke2022/supervisor.png" alt="久手堅 司">
						<p>監修<br>せたがや内科・神経内科クリニック<br>院長・医学博士<br>久手堅 司（くでけん つかさ）</p>
					</div>
				</div>
			</div>
		</section>
		<!-- /チェック -->

		<!-- クイズ -->
		<section class="lp__quiz">
			<h2 class="lp__quiz--title"><img src="~@/assets/img/lp_heatstroke2022/quiz_title.png" alt="5日でマスター！ 熱中症＆夏バテ対策クイズ"></h2>

			<div v-if="levelAchievement === 6" class="lp__quiz--body">
				<div class="lp__quiz--text">
					<i class="sprite-balloon img01" />
					<p class="lp__quiz--complete-text">クイズは全部回答済みじゃぞ。<br>スタンプをゲットするのじゃ！</p>
				</div>
				<ul class="lp__quiz--stamp">
					<li><img src="~@/assets/img/stamp/summer202207_stamp01.png" alt="暑すぎぃ"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp03.png" alt="夏に負けるニャ"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp04.png" alt="どんとこい夏"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp06.png" alt="突然の雨"></li>
				</ul>
				<div class="lp__quiz--level"><i :class="['sprite_level_achievement', `level${levelAchievement}`]" /></div>
				<a href="javascript:void(0)" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stamp`, _stampType: 'summer', _backPage: 'top' } })" class="lp__quiz--link gtm-link_click_count01" data-gtm-event-cat-label="熱中症＆夏バテ対策クイズリンク29" data-gtm-event-act-label="trackingLpHeatstroke2022Btn29">プレゼントをもらう</a>
			</div>

			<div v-else class="lp__quiz--body">
				<div class="lp__quiz--text">
					<i class="sprite-balloon img01" />
					<p>1日1問クイズに答えるのじゃ。<br>5回答えると、<em>スゴ得限定スタンプ</em>をプレゼントするぞ！</p>
				</div>
				<ul class="lp__quiz--stamp">
					<li><img src="~@/assets/img/stamp/summer202207_stamp01.png" alt="暑すぎぃ"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp03.png" alt="夏に負けるニャ"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp04.png" alt="どんとこい夏"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp06.png" alt="突然の雨"></li>
				</ul>
				<div class="lp__quiz--level"><i :class="['sprite_level_achievement', `level${levelAchievement}`]" /></div>
				<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=heatstrokeQuizProblem` } })" class="lp__quiz--link gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク13" data-gtm-event-act-label="trackingLpHeatstroke2022Btn13">クイズに答える</a>
			</div>

		</section>
		<!-- /クイズ -->

		<!-- アウトロ -->
		<div class="lp__outro">
			<p>with頭痛ーるで夏を元気に！</p>
			<img src="~@/assets/img/lp_heatstroke2022/outro_img.png" alt="with頭痛ーるで夏を元気に！イメージ">
			<a href="javascript:void(0)" @click="onScroll('js_pagetop')" class="lp__outro--button-pagetop gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク14" data-gtm-event-act-label="trackingLpHeatstroke2022Btn14">上部にもどる</a>

			<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="lp__outro--button-back gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク15" data-gtm-event-act-label="trackingLpHeatstroke2022Btn15">TOPにもどる</a>
		</div>
		<!-- /アウトロ -->

		<!-- 下に吸着するアプリダウンロードボタン -->
		<aside class="sticky-bottom-appdownload">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク16Android" data-gtm-event-act-label="trackingLpHeatstroke2022Btn16_android"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
			<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク16iOS" data-gtm-event-act-label="trackingLpHeatstroke2022Btn16_ios"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
		</aside>
		<!-- /下に吸着するアプリダウンロードボタン -->

		<!-- モーダル -->
		<v-ons-modal :visible="isVisibleModal" animation="fade" animationOptions="{duration: 0.2, timing: 'ease-in'}" class="lp__modal">
			<aside class="lp__modal--inner">
				<div v-show="modalType === 1" class="lp__modal--body">
					<!-- slider -->
					<div class="summerheat__slider">
						<div class="summerheat__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-summerheat">
								<li class="swiper-slide">
									<i class="sprite-summerheat_heat img01" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_heat img02" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_heat img03" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_heat img04" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_heat img05" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_heat img06" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_heat img07" />
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId }, hash: '#js_healthweather' })" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク23" data-gtm-event-act-label="trackingLpHeatstroke2022Btn23"><i class="sprite-summerheat_heat img08" /></a>
								</li>
							</ul>
						</div>
						<div class="summerheat__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 2" class="lp__modal--body">
					<!-- slider -->
					<div class="summerheat__slider">
						<div class="summerheat__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-summerheat">
								<li class="swiper-slide">
									<i class="sprite-summerheat_fatigue img01" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_fatigue img02" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_fatigue img03" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_fatigue img04" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_fatigue img05" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_fatigue img06" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_fatigue img07" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_fatigue img08" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_fatigue img09" />
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=balancecheckAutonomicNerves` } })" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク24" data-gtm-event-act-label="trackingLpHeatstroke2022Btn24"><i class="sprite-summerheat_fatigue img10" /></a>
								</li>
							</ul>
						</div>
						<div class="summerheat__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 3" class="lp__modal--body">
					<!-- slider -->
					<div class="summerheat__slider">
						<div class="summerheat__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-summerheat">
								<li class="swiper-slide">
									<i class="sprite-summerheat_cooler img01" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_cooler img02" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_cooler img03" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_cooler img04" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_cooler img05" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_cooler img06" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_cooler img07" />
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckTemperatureDifference` } })" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク25" data-gtm-event-act-label="trackingLpHeatstroke2022Btn25"><i class="sprite-summerheat_cooler img08" /></a>
								</li>
							</ul>
						</div>
						<div class="summerheat__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 4" class="lp__modal--body">
					<!-- slider -->
					<div class="summerheat__slider">
						<div class="summerheat__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-summerheat">
								<li class="swiper-slide">
									<i class="sprite-summerheat_sleep img01" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_sleep img02" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_sleep img03" />
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=balancecheckAutonomicNerves` } })" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク26" data-gtm-event-act-label="trackingLpHeatstroke2022Btn26"><i class="sprite-summerheat_sleep img04" /></a>
								</li>
							</ul>
						</div>
						<div class="summerheat__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 5" class="lp__modal--body">
					<!-- slider -->
					<div class="summerheat__slider">
						<div class="summerheat__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-summerheat">
								<li class="swiper-slide">
									<i class="sprite-summerheat_bathtub img01" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_bathtub img02" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_bathtub img03" />
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckTemperatureDifference` } })" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク27" data-gtm-event-act-label="trackingLpHeatstroke2022Btn27"><i class="sprite-summerheat_bathtub img04" /></a>
								</li>
							</ul>
						</div>
						<div class="summerheat__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 6" class="lp__modal--body">
					<!-- slider -->
					<div class="summerheat__slider">
						<div class="summerheat__slider--container swiper-container js_swiper">
							<ul class="swiper-wrapper sprite-summerheat">
								<li class="swiper-slide">
									<i class="sprite-summerheat_water img01" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_water img02" />
								</li>
								<li class="swiper-slide">
									<i class="sprite-summerheat_water img03" />
								</li>
								<li class="swiper-slide">
									<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId }, hash: '#js_healthweather' })" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症特集ページ2022リンク28" data-gtm-event-act-label="trackingLpHeatstroke2022Btn28"><i class="sprite-summerheat_water img04" /></a>
								</li>
							</ul>
						</div>
						<div class="summerheat__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div class="lp__modal--button">
					<a @click="clickModalLink(0, $event)">
						<ons-icon icon="md-close" />表示を閉じる
					</a>
				</div>
			</aside>
		</v-ons-modal>
		<!-- /モーダル -->
	</div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_USER_DATA } from '@/store/modules/common/mutation-types'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'
import Swiper from 'swiper'

export default {
	name: 'LpHeatstroke2022',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			isVisibleModal: false,
			modalType: 0,
			levelAchievement: 1
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['userId', 'userToken', 'userData', 'isAndroid'])
	},
	mounted () {
		if (!this.userData) {
			this.SET_USER_DATA({
				user_id: this.userId,
				user_token: this.userToken
			}).then(res => {
				console.log('userData', this.userData)
				this.init()
			}).catch(error => {
				console.log('Error SET_USER_DATA: ', error)
			})
		} else {
			this.init()
		}

		this.onSwiper()

		// ページ遷移後のアンカーリンク
		// NOTE: トップページでも実装、今後統一する予定
		if (this.$route.hash) {
			window.addEventListener('load', this.hashAnchorLink)
		}
	},
	beforeDestroy () {
		window.removeEventListener('load', this.hashAnchorLink)
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_USER_DATA]),

		// クイズの達成度
		init () {
			const webStorage = this.userData.teigaku.web_storage.data ? JSON.parse(this.userData.teigaku.web_storage.data) : null

			this.levelAchievement = (!webStorage.hasOwnProperty('q1') || webStorage.q1 === 0) ? 1
				: webStorage.q2 === 0 ? 2
					: webStorage.q3 === 0 ? 3
						: webStorage.q4 === 0 ? 4
							: webStorage.q5 === 0 ? 5
								: 6
		},

		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		},

		// モーダルのリンククリックイベント
		clickModalLink (value, event) {
			event.preventDefault()
			this.isVisibleModal = value > 0
			if (this.isVisibleModal) this.modalType = value
		},

		// Swiperの処理
		onSwiper () {
			const swiperConstructor = () => {
				let elmSwiper = document.querySelectorAll('.js_swiper')
				let elmNext = document.querySelectorAll('.js_swiper_next')
				let elmPrev = document.querySelectorAll('.js_swiper_prev')
				let elmPager = document.querySelectorAll('.js_swiper_pagination')
				let initSwiper
				for (let i = 0; i < elmSwiper.length; i++) {
					elmSwiper[i].className += i
					elmNext[i].className += i
					elmPrev[i].className += i
					elmPager[i].className += i
					initSwiper = new Swiper('.js_swiper' + i, {
						slidesPerView: 1,
						spaceBetween: 10,
						observer: true,
						observeParents: true,
						navigation: {
							nextEl: '.js_swiper_next' + i,
							prevEl: '.js_swiper_prev' + i
						},
						pagination: {
							el: '.js_swiper_pagination' + i
						}
					})
				}
				return initSwiper
			}
			swiperConstructor()
		},

		// ページ遷移後のアンカーリンク
		// NOTE: トップページでも実装、今後統一する予定
		hashAnchorLink () {
			const $elem = document.querySelector(this.$route.hash)
			if ($elem) $elem.scrollIntoView ? $elem.scrollIntoView({ behavior: 'smooth', block: 'start' }) : scrollTo(0, $elem.getBoundingClientRect().top)
		}

	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";
@import "~swiper/css/swiper.min.css";

// NOTE: LPページ限定の変数（デザインシステム外のため他での利用禁止）
$text-em: #FF4D5F;
$lp-background: #F5F5F5;
$lp-quiz-background: #99DAF1;
$lp-quiz-btn: #FE8F11;
$lp-quiz-btn-shadow: #C9720E;
$lp-contents-primary-color: #0071DB;
$lp-contents-secondary-color: #0859E4;
$lp-contents-background: #FFF2D4;
$lp-contents-btn-shadow: #004AC8;
$lp-pressure-forecast-background: #CEF3FF;
$spacing-12: 0.75rem;
$spacing-22: 1.375rem;
$spacing-34: 2.125rem;
$spacing-60: 3.75rem;
$line-height-1375: 1.375;
$max-width-374: 0.85333;

.lp {
	margin: 0 auto;
	max-width: 640px;
	font-family: $lp-family-primary;
	font-size: $font-size-16;
	background-color: $lp-background;

	&__header {
		margin: 0 0 $spacing-10;
		padding: $spacing-10 $spacing-14 0;

		img {
			margin: 0 auto;
			display: block;
			width: 100%;
		}
	}

	&__intro {
		padding-bottom: $spacing-18;

		&--text {
			margin: 0;
			padding: 0 $spacing-14;
			line-height: $line-height-1375;

			em {
				color: $text-em;
				font-family: $lp-family-tertiary;
				font-style: normal;
			}
		}
	}

	&__link {
		padding-bottom: $spacing-30;
		background-color: $lp-contents-background;

		&--title {
			margin: 0;
			padding: 0;

			img {
				margin: 0 auto;
				display: block;
				width: 100%;
			}
		}

		&--list {
			margin: 0 auto;
			padding: 0 $spacing-14;
			display: flex;
			justify-content: space-between;
			align-items: center;

			li {
				width: 31.12%;

				img {
					margin: 0 auto;
					display: block;
					width: 100%;
				}
			}
		}
	}

	&-contents {
		overflow: hidden;
		background-color: $lp-contents-background;

		&__inner {
			margin: 0 auto $spacing-30;
			padding: $spacing-30 $spacing-20 $spacing-20;
			border-radius: 10px;
			width: calc(100% - 1.625rem);
			background: linear-gradient(to bottom, $lp-contents-primary-color $spacing-10, $text-seconday $spacing-10 100%);
			box-sizing: border-box;

			&--healthweather {
				padding-top: $spacing-14;
			}
		}

		&__title {
			margin: 0 0 $spacing-14;
			padding: 0 0 $spacing-22;
			color: $lp-contents-secondary-color;
			font-family: $lp-family-tertiary;
			font-weight: normal;
			font-size: $font-size-24;
			text-align: center;
			background: url(~@/assets/img/lp_heatstroke2022/contentstitle_bg.png) bottom center / 266px auto no-repeat;
			line-height: $line-height-1375;

			span {
				display: block;
				font-family: $lp-family-tertiary;
				font-size: $font-size-16;

				@media (max-width: (370px)) {
					font-size: $font-size-14;
				}
			}

			&--healthweather {
				margin: 0 auto;
				display: block;
				width: 99.353%;
			}

			@media (max-width: (370px)) {
				font-size: $font-size-18;
			}
		}

		&__button {
			margin: 0 auto;
			padding: $spacing-10 0;
			border-radius: 6px;
			display: block;
			max-width: 295px;
			color: $text-seconday;
			font-family: $lp-family-secondary;
			font-style: normal;
			font-size: $font-size-18;
			text-align: center;
			text-decoration: none;
			background-color: $lp-contents-primary-color;
			box-shadow: 0 3px 0 0 $lp-contents-btn-shadow;

			@media (max-width: (374px)) {
				font-size: $font-size-16;
			}
		}

		&__healthweather {
			&--subtitle {
				margin: 0 auto $spacing-20;
				font-family: $lp-family-secondary;
				font-style: normal;
				font-size: $font-size-16;
				text-align: center;

				@media (max-width: (370px)) {
					font-size: $font-size-12;
				}
			}

			&--list {
				margin: 0 0 $spacing-16;
				padding: 0;
				line-height: $line-height-1375;

				em {
					color: $text-em;
					font-family: $lp-family-secondary;
					font-style: normal;
				}
			}

			&--text01 {
				margin: 0 auto $spacing-14;
				width: 307px;
				height: 160px;
				position: relative;

				@media (max-width: (374px)) {
					width: calc(307px * #{$max-width-374} );
					height: calc(160px * #{$max-width-374} );
				}

				p {
					margin: 0;
					position: absolute;
					right: 19px;
					top: 10px;
					width: 154px;
					font-size: $font-size-14;

					@media (max-width: (374px)) {
						right: 14px;
						width: calc(156px * #{$max-width-374} );
						font-size: $font-size-12;
					}
				}
			}

			&--text02 {
				margin: $spacing-20 auto 0;
				width: 307px;
				height: 103px;
				position: relative;

				@media (max-width: (374px)) {
					width: calc(307px * #{$max-width-374} );
					height: calc(103px * #{$max-width-374} );
				}

				p {
					margin: 0;
					position: absolute;
					right: 7px;
					top: 9px;
					width: 200px;
					font-size: $font-size-14;

					@media (max-width: (374px)) {
						right: 5px;
						width: calc(200px * #{$max-width-374} );
						font-size: $font-size-12;
					}
				}
			}
		}

		&__pressure {
			&--text01 {
				margin: 0 auto $spacing-20;
				width: 307px;
				height: 77.5px;
				position: relative;

				@media (max-width: (374px)) {
					width: calc(307px * #{$max-width-374} );
					height: calc(77.5px * #{$max-width-374} );
				}

				p {
					margin: 0;
					position: absolute;
					left: 10px;
					top: 9px;
					width: 200px;
					font-size: $font-size-14;

					@media (max-width: (374px)) {
						top: 8px;
						left: 10px;
						width: calc(200px * #{$max-width-374} );
						font-size: $font-size-12;
					}
				}
			}
			&--text02 {
				margin: 0 auto $spacing-20;
				width: 304px;
				height: 123px;
				position: relative;

				@media (max-width: (374px)) {
					width: calc(304px * #{$max-width-374} );
					height: calc(123px * #{$max-width-374} );
				}

				p {
					margin: 0;
					position: absolute;
					right: 9px;
					top: 9px;
					width: 200px;
					font-size: $font-size-14;

					@media (max-width: (374px)) {
						top: 8px;
						right: 6px;
						width: calc(200px * #{$max-width-374} );
						font-size: $font-size-12;
					}
				}
			}

			&--forecast {
				margin-bottom: $spacing-20;

				h3 {
					margin: 0 auto;
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
					padding: $spacing-4 0;
					color: $text-seconday;
					font-family: $lp-family-secondary;
					font-style: normal;
					font-size: $font-size-16;
					text-align: center;
					background-color: $lp-contents-primary-color;
				}

				&-box {
					padding: $spacing-14 $spacing-14 $spacing-18;
					background-color: $lp-pressure-forecast-background;
					border: 2px dashed $lp-contents-primary-color;
					border-top: none;
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;

					p {
						margin: 0 auto $spacing-10;
						font-size: $font-size-14;
					}

					img {
						margin: 0 auto;
						display: block;
						width: 72.203%
					}
				}
			}

			&--text03 {
				margin: $spacing-20 auto $spacing-14;
				width: 307px;
				height: 103px;
				position: relative;

				@media (max-width: (374px)) {
					width: calc(307px * #{$max-width-374} );
					height: calc(103px * #{$max-width-374} );
				}

				p {
					margin: 0;
					position: absolute;
					left: 10px;
					top: 9px;
					width: 200px;
					font-size: $font-size-14;

					@media (max-width: (374px)) {
						top: 8px;
						width: calc(200px * #{$max-width-374} );
						font-size: $font-size-12;
					}
				}
			}

			&--catch {
				margin-bottom: $spacing-4;
				font-family: $lp-family-secondary;
				text-align: center;
				color: $text-em;

				@media (max-width: (374px)) {
					font-size: $font-size-14;
				}
			}
		}

		&__summerheat {
			&--title {
				margin: 0 0 $spacing-18;
				font-family: $lp-family-primary;
				font-weight: normal;
				font-size: $font-size-16;
				text-align: center;
				line-height: $line-height-1375;

				@media (max-width: (350px)) {
					font-size: $font-size-14;
				}

				span {
					color: $text-em;
					font-family: $lp-family-tertiary;
				}

				&:nth-of-type(n + 2) {
					margin-top: $spacing-14;
				}
			}

			&--list {
				margin: 0;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;

				li {
					width: 32%;

					img {
						display: block;
						width: 100%;
					}
				}
			}
		}
		&__check {
			&--text {
				margin: 0 0 $spacing-16;
				line-height: $line-height-1375;
			}

			&--link {
				padding: 0;
				display: block;

				img {
					margin: 0 auto $spacing-16;
					display: block;
					width: 100%;
				}
			}

			&--profile {
				margin: 0 auto;
				padding: $spacing-10 $spacing-12;
				border-radius: 10px;
				background-color: $lp-background;
				display: flex;
				align-items: center;
				justify-content: space-between;
				box-sizing: border-box;

				img {
					display: block;
					width: 72px;
				}

				p {
					margin: 0;
					font-size: $font-size-12;
					width: calc(100% - 84px);

				}
			}
		}
	}

	&__quiz {
		padding-bottom: $spacing-60;
		background: url(~@/assets/img/lp_heatstroke2022/quiz_bottom.png) bottom center / 100% auto no-repeat $lp-quiz-background;

		&--title {
			margin: 0;
			background-color: $lp-contents-background;

			img {
				margin: 0 auto;
				display: block;
				width: 100%;
			}
		}

		&--text {
			margin: 0 auto $spacing-14;
			width: 335px;
			height: 82px;
			position: relative;

			@media (max-width: (374px)) {
				width: calc(335px * #{$max-width-374} );
				height: calc(82px * #{$max-width-374} );
			}

			p {
				margin: 0;
				position: absolute;
				right: 8px;
				top: 9px;
				width: 230px;
				font-size: $font-size-14;

				&.lp__quiz--complete-text {
					top: 18px;
				}

				@media (max-width: (374px)) {
					right: 6px;
					width: calc(230px * #{$max-width-374} );
					font-size: $font-size-12;
				}

				em {
					color: $text-em;
					font-family: $lp-family-tertiary;
					font-style: normal;
				}
			}
		}

		&--stamp {
			margin: 0 auto $spacing-14;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 345px;
			width: auto;

			@media (max-width: (374px)) {
				max-width: 300px;
			}

			li {
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 78px;
				height: 78px;
				background-color: $background-primary;
				overflow: hidden;

				@media (max-width: (374px)) {
					width: 68px;
					height: 68px;
				}

				img {
					display: block;
					width: 100%;
					pointer-events: none;
				}
			}
		}

		&--level {
			margin: 0 auto $spacing-20;
			width: 335px;
			height: 105px;

			@media (max-width: (374px)) {
				width: calc(335px * #{$max-width-374} );
				height: calc(105px * #{$max-width-374} );
			}
		}

		&--link {
			margin: 0 auto;
			padding: $spacing-8 0;
			border-radius: 6px;
			display: block;
			color: $text-seconday;
			width: 78.66%;
			font-family: $lp-family-tertiary;
			font-size: $font-size-18;
			background-color: $lp-quiz-btn;
			text-align: center;
			text-decoration: none;
			box-shadow: 0 3px 0 0 $lp-quiz-btn-shadow;
		}
	}

	&__outro {
		margin-top: $spacing-30;

		p {
			margin: 0 0 $spacing-14;
			text-align: center;
			font-family: $lp-family-secondary;
			font-weight: normal;
		}

		img {
			margin: 0 auto $spacing-20;
			display: block;
			width: 66.66%;
		}

		&--button {
			margin: $spacing-34 0 0 0;

			&-pagetop {
				margin: 0 auto $spacing-16;
				padding: $spacing-6 0;
				border: 3px solid $primary;
				border-radius: 100px;
				position: relative;
				display: block;
				max-width: 295px;
				color: $primary;
				font-family: $lp-family-secondary;
				font-weight: normal;
				font-size: $font-size-18;
				background-color:  $background-primary;
				text-align: center;
				text-decoration: none;
			}

			&-back {
				margin: 0 auto;
				padding:$spacing-8 0;
				border-radius: 100px;
				position: relative;
				display: block;
				max-width: 295px;
				color: $text-seconday;
				font-family: $lp-family-secondary;
				font-weight: normal;
				font-size: $font-size-24;
				background-color: $primary;
				text-align: center;
			}
		}
	}

	&__modal {
		color: $text-primary;
		position: fixed;
		height: 100vh;

		/deep/ .modal__content {
			padding-top: 10vh;
			vertical-align: top;
		}

		&--inner {
			margin: 0 auto;
			width: 347px;
			background-color: $background-primary;

			@media (max-width: (374px)) {
				width: 310px;
			}
		}

		&--body {
			position: relative;
		}

		&--button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 35px;
			background: $background-secondary;
			box-sizing: border-box;

			& > a {
				color: $text-primary;
				font-size: $font-size-12;

				ons-icon {
					font-size: $font-size-16;
					vertical-align: middle;
					margin-right: $spacing-10;
				}
			}
		}
	}
}

.sticky-bottom-appdownload {
	margin-top: $spacing-20;
	padding: $spacing-8 $spacing-14;
	box-shadow: 0px -2px 2px rgba(0,0,0,0.1);
}

// swiper
.swiper-container {
	margin: 0;
	@media (max-width: (374px)) {
		height: 310px;
	}
}
.swiper-wrapper {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.summerheat__slider--nav {
	.swiper-button-prev,
	.swiper-button-next {
		margin-top: -40px;
		width: 40px;
		height: 40px;

		&:before,
		&:after {
			display: none;
		}

		&.swiper-button-disabled {
			opacity: 0;
		}
	}
	.swiper-button-prev {
		left: 10px;
		background: url(~@/assets/img/lp_heatstroke2022/slider_prev.png) center center / 100% auto no-repeat;
	}
	.swiper-button-next {
		right: 10px;
		background: url(~@/assets/img/lp_heatstroke2022/slider_next.png) center center / 100% auto no-repeat;
	}
}

.swiper-pagination-bullets {
	padding: $spacing-14 0;
	display: flex;
	align-items: center;
	justify-content: center;
	/deep/ .swiper-pagination-bullet {
		margin: 0 $spacing-10;
		width: 5px;
		height: 5px;
		background: $button-secondary;
		opacity: 1;
	}
	/deep/ .swiper-pagination-bullet-active {
		background: $text-primary;
	}
}

// sprite
.sprite-balloon {
	background-image: url(~@/assets/img/lp_heatstroke2022/sprite_balloon.png);
    background-repeat: no-repeat;
    display: block;
	background-size: (690px / 2) (1417px / 2);

	@media (max-width: (374px)) {
		transform: scale(.85333);
		transform-origin: 0 0;
	}

	&.img01 {
		width: calc(670px / 2);
		height: calc(164px / 2);
		background-position: (-10px / 2) (-10px / 2);
	}
	&.img02 {
		width: calc(614px / 2);
		height: calc(320px / 2);
		background-position: (-10px / 2) (-194px / 2);
	}
	&.img03 {
		width: calc(614px / 2);
		height: calc(206px / 2);
		background-position: (-10px / 2) (-534px / 2);
	}
	&.img04 {
		width: calc(614px / 2);
		height: calc(155px / 2);
		background-position: (-10px / 2) (-760px / 2);
	}
	&.img05 {
		width: calc(608px / 2);
		height: calc(246px / 2);
		background-position: (-10px / 2) (-935px / 2);
	}
	&.img06 {
		width: calc(614px / 2);
		height: calc(206px / 2);
		background-position: (-10px / 2) (-1201px / 2);
	}
}

.sprite_level_achievement {
	background-image: url(~@/assets/img/lp_heatstroke2022/sprite_quiz_progress.png);
    background-repeat: no-repeat;
    display: block;
	background-size: (690px / 2) (1380px / 2);

	margin: 0 auto;
	width: calc(670px / 2);
	height: calc(210px / 2);

	@media (max-width: (374px)) {
		transform: scale(.85333);
		transform-origin: 0 0;
	}

	&.level1 {
		background-position: (-10px / 2) (-10px / 2);
	}
	&.level2 {
		background-position: (-10px / 2) (-240px / 2);
	}
	&.level3 {
		background-position: (-10px / 2) (-470px / 2);
	}
	&.level4 {
		background-position: (-10px / 2) (-700px / 2);
	}
	&.level5 {
		background-position: (-10px / 2) (-930px / 2);
	}
	&.level6 {
		background-position: (-10px / 2) (-1160px / 2);
	}
}

.sprite-summerheat {
	&_heat {
		background-image: url(~@/assets/img/lp_heatstroke2022/sprite_summerheat_heat.png);
		background-size: (714px / 2) (5712px / 2);
	}
	&_fatigue {
		background-image: url(~@/assets/img/lp_heatstroke2022/sprite_summerheat_fatigue.png);
		background-size: (714px / 2) (7140px / 2);
	}
	&_cooler {
		background-image: url(~@/assets/img/lp_heatstroke2022/sprite_summerheat_cooler.png);
		background-size: (714px / 2) (5718px / 2);
	}
	&_sleep {
		background-image: url(~@/assets/img/lp_heatstroke2022/sprite_summerheat_sleep.png);
		background-size: (714px / 2) (2856px / 2);
	}
	&_bathtub {
		background-image: url(~@/assets/img/lp_heatstroke2022/sprite_summerheat_bathtub.png);
		background-size: (714px / 2) (2856px / 2);
	}
	&_water {
		background-image: url(~@/assets/img/lp_heatstroke2022/sprite_summerheat_water.png);
		background-size: (714px / 2) (2856px / 2);
	}
	i {
		background-repeat: no-repeat;
		display: block;
		width: 347px;
		height: 347px;

		@media (max-width: (374px)) {
			transform: scale(.89336);
			transform-origin: 0 0;
		}
	}

	.img01 {
		background-position: (-10px / 2) (-10px / 2);
	}

	.img02 {
		background-position: (-10px / 2) (-724px / 2);
	}

	.img03 {
		background-position: (-10px / 2) (-1438px / 2);
	}

	.img04 {
		background-position: (-10px / 2) (-2152px / 2);
	}

	.img05 {
		background-position: (-10px / 2) (-2866px / 2);
	}

	.img06 {
		background-position: (-10px / 2) (-3580px / 2);
	}

	.img07 {
		background-position: (-10px / 2) (-4294px / 2);
	}

	.img08 {
		background-position: (-10px / 2) (-5008px / 2);
	}

	.img09 {
		background-position: (-10px / 2) (-5722px / 2);
	}

	.img10 {
		background-position: (-10px / 2) (-6436px / 2);
	}
}
</style>
